import FooterBottom from "./footerBottom/FooterBottom";

import data from "../../../../assets/data/footer/footerDataV3";
import footerLogo from "../../../../assets/images/newLogo.png";
import btnArrow from "../../../../assets/images/blog/arrow-icon.png";

import FooterStyleWrapper from "./Footer.style";
const Footer = () => {
  return (
    <FooterStyleWrapper>
      <div className="top-footer-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="footer_image">
                <a href="# ">
                  <img src={footerLogo} alt="bithu nft footer logo" style={{width: 145}} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* footer bottom  */}
      <FooterBottom />
    </FooterStyleWrapper>
  );
};

export default Footer;
