
import GlobalStyles from "../assets/styles/GlobalStyles";
import Layout from "../common/layout";
import Header from "../components/section/header/v1/Header";
import PageHeader from "../common/pageHeader/v2";
import Footer from "../components/section/footer/v3";
import TeamMember from "./teamMember";
import TeamRegister from "./TeamRegister";
import { useEffect } from "react";
import { useState } from "react";
import { collection, query, getFirestore, onSnapshot, where, setDoc, doc } from "firebase/firestore";
import { connectWallet } from "../lib/metamaskhandler";

import { initializeApp } from "firebase/app";
import TeamConnected from "./teamConnected";

import axios from 'axios';

const firebaseConfig = {
  apiKey: "AIzaSyCWLf9niORGn3oS40cqj35mXtt0A5d-SGU",
  authDomain: "arizona-rp-94de8.firebaseapp.com",
  projectId: "arizona-rp-94de8",
  storageBucket: "arizona-rp-94de8.appspot.com",
  messagingSenderId: "469479243089",
  appId: "1:469479243089:web:3bae3eba45a295ec1f2770",
  measurementId: "G-DTQ6CH552Z"
};

const app = initializeApp(firebaseConfig);

const AboutPage = () => {

  const [connect, setConnect] = useState(false)
  const [load_state, setLoad] = useState(false)
  const [gold, setGold] = useState(0.0)
  const [usdt, setUsdt] = useState(0.0)
  const [id, setId] = useState("")


  const getWallet = async () => {
    const account = await connectWallet();
    return account[0]
  };

  useEffect(()=>{
    load()
  },[])

  async function load(){
    setLoad(true)
    let db = getFirestore(app);
    let res = await getWallet()

    const q = query(collection(db, "Users"), where("wallet", "==", res));

    onSnapshot(q, async (querySnapshot) => {
      setConnect(false)
      querySnapshot.forEach((doc) => {
        
        setConnect(true)
        setId(doc.id)
        res = doc.data()
        setUsdt(res.usdt)
        fetch(`https://arizona-api-redirect.vercel.app/app/redirect/get_hello7/${res.id_steam}`)
        .then(response => response.json())
        .then(data => {
          const persons = data;
          setGold(persons.gold)
          setLoad(false)
        })
        .catch(error => {
          console.error(error);
        });
      });
    });
  }

  return (
    <>
      <Layout>
        <GlobalStyles />
        <Header />
        <PageHeader />
        {!connect?<div style={{width: "100%", display: 'flex', justifyContent: 'space-around'}}>
          <TeamMember />
          <TeamRegister />
        </div>:<div style={{width: "100%", display: 'flex', justifyContent: 'center'}}>
          <TeamConnected gold={gold} usdt={usdt} load={load_state} app={app} id={id} />
        </div>}
        <Footer />
      </Layout>
    </>
  );
};

export default AboutPage;
