import TeamMemberStyleWrapper from "./TeamMember.style";
import hoverShape from "../../../src/assets/images/icon/hov_shape_L.svg"
import avatarThumb1 from "./avatar.png";

const TeamMember = ({ }) => {
  return (
    <TeamMemberStyleWrapper>
      <div className="bithu_team_card">
        <div className="team_member_img">
          <img src={avatarThumb1} />
        </div>
        <div style={{height: 10}}></div>
        <h4>Carteira não <br/>vinculada</h4>
        <span className="hover_shape hov_shape1">
          <img src={hoverShape} alt="bithu nft team" />
        </span>
        <span className="hover_shape hov_shape2">
          <img src={hoverShape} alt="" />
        </span>
      </div>
    </TeamMemberStyleWrapper>
  );
};

export default TeamMember;
