const data = [
    {
        num: "1",
        mintColor: "green",
        text: "Connecte ao servidor"
    },
    {
        num: "2",
        mintColor: "blue",
        text: "Baixe o nosso app"
    },
    {
        num: "3",
        mintColor: "yellow",
        text: "Connecte o app a seu personagem"
    },
    {
        num: "4",
        mintColor: "red",
        text: "Vizualize promo codes e ganhe gold"
    },
    {
        num: "5",
        mintColor: "green",
        text: "Troque gold por $USDT"
    },
]

export default data;