import { FaRegHeart } from "react-icons/fa";
import ProductStyleWrapper from "./Product.style";
import gold from "./gold.png";


const Product = ({ img, name, value }) => {
  return (
    <ProductStyleWrapper>
      <div className="product_thumb">
        <img src={img} alt="thumb" />
      </div>
      <div className="product_details">
        <a href="#">{name}</a>
        <p>
          <span></span>
          <p>
            {value} 
            <img src={gold} alt="thumb" style={{width: "30px"}} /> 
          </p>
        </p>
      </div>
    </ProductStyleWrapper>
  );
};

export default Product;
