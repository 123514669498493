const data = [
  {
    title: "Como ganho $USDT",
    text: "A cada 20 minutos logado no servidor sera passado um qr code no lado superior direito, escaneando esse qr code em nosso applicativo você receberá 0.1 de GOLD que pode ser trocado por $USDT.",
  },
  {
    title: "Como connecto meu personagem ao app",
    text: "Conectado no servidor basta digitar o comando /connect-app e em seu applicativo aperte no botão escanear qr-code, escaneando o qr-code corretamente sua wallet irá conectar. ",
  },
  {
    title: "Como connecto minha metameask ao app",
    text: "Para conectar sua metameask ao app, basta conectar sua metameask em nosso site em seguida acessar a pagina minha conta em nosso site e escanear o qr-code utilizando nosso applicativo para vincular sua metameask com o seu app e conseguir sacar seu $USDT.",
  },
  {
    title: "Onde baixo o app",
    text: "Nosso app está disponivel na Google Play e na Apple Store basta procurar por: Arizona County.",
  },
  {
    title: "Maneiras de se ganhar $GOLD",
    text: "Você consegue ganhar $GOLD escaneando qr-codes que passam enquanto você está online no servidor, realizando roubo a bancos,lojas ou forts.",
  },
  {
    title: "Como troco $GOLD por $USDT",
    text: "Para trocar $GOLD por $USDT voce precisara conectar seu app a metameask, realizando este passo você terá acesso a sua conta onde podera realizar a troca de $GOLD por $USDT e o saque para sua metameask.",
  },
];

export default data;
