import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyCWLf9niORGn3oS40cqj35mXtt0A5d-SGU",
  authDomain: "arizona-rp-94de8.firebaseapp.com",
  projectId: "arizona-rp-94de8",
  storageBucket: "arizona-rp-94de8.appspot.com",
  messagingSenderId: "469479243089",
  appId: "1:469479243089:web:3bae3eba45a295ec1f2770",
  measurementId: "G-DTQ6CH552Z"
};

export const app = initializeApp(firebaseConfig);