import FilterBox from "./filterBox/FilterBox";
import Product from "./product/Product";
import Pagination from "../../../common/pagination/Pagination";

import CollectionStyleWrapper from "./Collection.style";

import { products } from "../../../assets/data/collectionsData";
import { useState } from "react";
import MetamaskModal from "../../../common/modal/metamaskModal/MetamaskModal";

const Collection = ({peds}) => {
  
  const [ped, setPed] = useState(false)
  const [modal, setModal] = useState(false)
  return (
    <CollectionStyleWrapper>
    
      {modal && <MetamaskModal handleMetamaskModal={()=>setModal(false)} ped={ped} />}
      <div className="container">
        <div className="row filters_row">
          <div className="col-lg-12 col-md-12" style={{display: "flex", justifyContent: "center"}}>
            <div className="col-lg-10 col-md-10">
              <div className="row products_row">
                {peds?.map((product, idx) => (
                  <div onClick={()=>{setModal(true); setPed(product)}} key={idx} className="col-3">
                    <Product {...product} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </CollectionStyleWrapper>
  );
};

export default Collection;
