import TeamMemberStyleWrapper from "./TeamMember.style";
import hoverShape from "../../../src/assets/images/icon/hov_shape_L.svg"
import avatarThumb1 from "./avatar.png";
import QRCode from 'qrcode'
import { useEffect } from "react";
import { useState } from "react";
import { connectWallet } from "../../lib/metamaskhandler";

import { collection, query, getFirestore, onSnapshot, where, setDoc, doc } from "firebase/firestore";
import { app } from "../firebase.tsx";

const TeamRegister = ({ }) => {
  const [qr, setQr] = useState("")

  useEffect(()=>{
    generateQR("test")
  })

  const getWallet = async () => {
    const account = await connectWallet();
    return account[0]
  };

  const generateQR = async text => {
    try {
      let res = await getWallet()
      setQr(await QRCode.toDataURL("connect_wallet_web_" + res))
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <TeamMemberStyleWrapper>
      <div className="bithu_team_card">
        <div className="team_member_img">
        </div>
        <h4>Abra o app e selecione a opção connectar carteira</h4>
        <div style={{height: 10}}></div>
        <div className="team_member_img">
          <img src={ qr} />
        </div>
        <div style={{height: 10}}></div>
        <h4>Scanei o qr-code para vincular sua carteira</h4>
        <span className="hover_shape hov_shape1">
          <img src={hoverShape} alt="bithu nft team" />
        </span>
        <span className="hover_shape hov_shape2">
          <img src={hoverShape} alt="" />
        </span>
      </div>
    </TeamMemberStyleWrapper>
  );
};

export default TeamRegister;
