import { useModal } from "../../../utils/ModalContext";
import { FiX, FiChevronRight } from "react-icons/fi";

import WalletModalStyleWrapper from "./WalletHistory.style";
import hoverShape from "../../../assets/images/icon/hov_shape_L.svg";
import { connectWallet } from "../../../lib/metamaskhandler";
import { collection, getFirestore, onSnapshot, query, where } from "firebase/firestore";
import { useEffect } from "react";
import { initializeApp } from "firebase/app";
import { useState } from "react";

const firebaseConfig = {
  apiKey: "AIzaSyCWLf9niORGn3oS40cqj35mXtt0A5d-SGU",
  authDomain: "arizona-rp-94de8.firebaseapp.com",
  projectId: "arizona-rp-94de8",
  storageBucket: "arizona-rp-94de8.appspot.com",
  messagingSenderId: "469479243089",
  appId: "1:469479243089:web:3bae3eba45a295ec1f2770",
  measurementId: "G-DTQ6CH552Z"
};

const app = initializeApp(firebaseConfig);

const WalletHistory = ({cb}) => {
  const [arr, setArr] = useState([])


  const getWallet = async () => {
    const account = await connectWallet();
    return account[0]
  };

  useEffect(()=>{
    load()
  },[])

  async function load(){
    let db = getFirestore(app);
    let res = await getWallet()

    const q = query(collection(db, "Withdrawals"), where("wallet", "==", res));

    onSnapshot(q, async (querySnapshot) => {
      let arr_ = []
      querySnapshot.forEach((doc) => {
        res = doc.data()
        arr_.push(res)
        setArr(arr_)
      });
    });
  }

  return (
    <>
      <WalletModalStyleWrapper className="modal_overlay">
        <div className="mint_modal_box">
          <div className="mint_modal_content">
            <div className="modal_header">
              <h2>Histórico  Saque</h2>
              <button onClick={() => cb()}>
                <FiX />
              </button>
            </div>
            <div style={{height: 20}}></div>
            <div>
              <div style={{display: "flex", justifyContent: "space-between"}}>
                <p className="text">wallet</p>
                <p className="text">valor</p>
                <p className="text">hash</p>
                <p className="text">status</p>
              </div>
              <div style={{height: 2, backgroundColor: 'gray'}}></div>
            </div>
            {arr?.map((member, i) => (
                  <div>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                      <p className="text">{member.wallet.substr(0, 3)}...{member.wallet.substr(member.wallet.length - 3)}</p>
                      <p className="text">{member.value}</p>
                      <p className="text">{member.hash}</p>
                      <p className="text" style={{color: member.status == "concluido" ? "green" : "orange"}}>{member.status}</p>
                    </div>
                    <div style={{height: 2, backgroundColor: 'gray'}}></div>
                  </div>
              ))}

            <div className="modal_bottom_shape_wrap">
              <span className="modal_bottom_shape shape_left">
                <img src={hoverShape} alt="bithu nft hover shape" />
              </span>
              <span className="modal_bottom_shape shape_right">
                <img src={hoverShape} alt="bithu nft hover shape" />
              </span>
            </div>
          </div>
        </div>
      </WalletModalStyleWrapper>
    </>
  );
};

export default WalletHistory;
