import aboutCardIcon1 from "../../images/icon/aboutus_card_icon1.svg"
import aboutCardIcon2 from "../../images/icon/aboutus_card_icon2.svg"
import aboutCardIcon3 from "../../images/icon/aboutus_card_icon3.svg"

 const data = {
    aboutDescription1: 'O nosso servidor de RP é dedicado a fornecer a melhor experiência possível para jogadores que procuram por um ambiente de roleplay imersivo e divertido. Nós valorizamos a criatividade e a liberdade dos jogadores para criar suas próprias histórias e personagens, e trabalhamos duro para oferecer suporte de qualidade e um ambiente seguro e acolhedor para todos.',
    aboutDescription2: 'A nossa equipe é formada por jogadores experientes e apaixonados por RP, e estamos sempre abertos a sugestões e feedbacks para melhorar a experiência de jogo. Nós nos esforçamos para manter um equilíbrio entre a liberdade dos jogadores e a manutenção da integridade do jogo, e trabalhamos duro para garantir que o nosso servidor seja um lugar divertido e agradável para jogar. Venha se juntar a nós e experimente o emocionante mundo do RP!',
    aboutInfo: [
        {
            icon: aboutCardIcon1,
            title: "Economia",
            text: "Uma economia equilibrada é crucial para evitar desigualdades e manter a imersão dos jogadores."
        },
        {
            icon: aboutCardIcon2,
            title: "Play to Earn",
            text: 'Você pode ganhar $USDT enquanto se diverte em uma experiência envolvente e imersiva.'
        },
        {
            icon: aboutCardIcon3,
            title: "Metaverso",
            text: 'Estamos construindo um futuro metaverso imersivo onde você poderá viver, trabalhar e se divertir.'
        }
    ]
}

export default data;