import { useModal } from "../../../utils/ModalContext";
import QRCode from 'qrcode'
import { FiX, FiChevronRight } from "react-icons/fi";
import MetamaskModalStyleWrapper from "./MetamaskModal.style.js";
import hoverShape from "../../../../src/assets/images/icon/hov_shape_L.svg"
import metamaskIcon from "../../../assets/images/icon/MetaMask.svg";
import gold from "./gold.png";
import { useState } from "react";
import { useEffect } from "react";

const MetamaskModal = ({handleMetamaskModal, ped}) => {
  const [qr, setQr] = useState("")
  const [buy, setClick] = useState(false)

  useEffect(()=>{
    generateQR(ped.name)
  })

  const generateQR = async text => {
    try {
      setQr(await QRCode.toDataURL("buy_ped_" + text))
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      <MetamaskModalStyleWrapper className="modal_overlay">
        <div className="mint_modal_box">
          <div className="mint_modal_content">
            <div className="modal_header">
              <h2>Comprar ped</h2>
              <p>{ped.name}</p>
              <button onClick={() => handleMetamaskModal()}>
                <FiX />
              </button>
            </div>
            <div className="modal_body text-center">
              {!buy ? <img src={ped.img} alt="thumb" />: <div></div>}
              {!buy ? <div style={{height: "20px"}}></div>: <div></div>}
              <div style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
                <p>{ped.value}</p>
                <img src={gold} alt="thumb" style={{width: "30px", height: "30px"}} /> 
              </div>
              {buy ?  
              <div>
                <h5 style={{color: "white"}}>Abra o app e selecione a opção comprar ped</h5>
                <div style={{height: 10}}></div>
                <div className="team_member_img">
                  <img src={qr} />
                </div>
                <div style={{height: 10}}></div>
                <h5 style={{color: "white"}}>Scanei o qr-code para finalizar a compra!</h5>
              </div>: <div></div>}
              {!buy ?<div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                <div className="bithu_team_card" style={{cursor: "pointer"}} onClick={()=>setClick(true)}>
                  <h4>Comprar</h4>
                  <span className="hover_shape hov_shape1">
                    <img src={hoverShape} alt="bithu nft team" />
                  </span>
                  <span className="hover_shape hov_shape2">
                    <img src={hoverShape} alt="" />
                  </span>
                </div>
              </div>:<div></div>}
            </div>
          </div>
        </div>
      </MetamaskModalStyleWrapper>
    </>
  );
};

export default MetamaskModal;
