import { useModal } from "../utils/ModalContext";
import GlobalStyles from "../assets/styles/GlobalStyles";
import Layout from "../common/layout";
import Header from "../components/section/header/v1/Header";
import PageHeader from "../common/pageHeader/v4";
import Collection from "../components/section/collection";
import CTA from "../components/section/cta/v2";
import Footer from "../components/section/footer/v3";
import MetamaskModal from "../common/modal/metamaskModal/MetamaskModal";
import WalletModal from "../common/modal/walletModal/WalletModal";
import { initializeApp } from "firebase/app";
import { useEffect, useState } from "react";
import { collection, getFirestore, onSnapshot, query } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCWLf9niORGn3oS40cqj35mXtt0A5d-SGU",
  authDomain: "arizona-rp-94de8.firebaseapp.com",
  projectId: "arizona-rp-94de8",
  storageBucket: "arizona-rp-94de8.appspot.com",
  messagingSenderId: "469479243089",
  appId: "1:469479243089:web:3bae3eba45a295ec1f2770",
  measurementId: "G-DTQ6CH552Z"
};

const app = initializeApp(firebaseConfig);

const CollectionsPage = () => {

  const [peds, setPeds] = useState([])

  useEffect(()=>{
    let db = getFirestore(app);

    const q = query(collection(db, "Peds"));
    onSnapshot(q, async (querySnapshot) => {
      setPeds([])
      var r = [];
      querySnapshot.forEach((doc) => {
        if(doc.data().available == null){
          r.push(doc.data())
          setPeds(r)
        }else{
          if(doc.data().available == true){
            r.push(doc.data())
            setPeds(r)
          }
        }
      });
    });
  },[])

  return (
    <>
      <Layout>
        <GlobalStyles />
        <Header />
        <PageHeader />
        <Collection peds={peds} />
        <Footer />
      </Layout>
    </>
  );
};

export default CollectionsPage;
