import { useEffect, useState } from "react";
import { useModal } from "../../../../utils/ModalContext";
import { FaWallet, FaUser } from "react-icons/fa";
import Navigation from "./Navigation";
import Button from "../../../../common/button";
import HeaderStyleWrapper from "./Header.style";
import { FiX, FiChevronRight } from "react-icons/fi";
import {
  connectWallet,
} from "../../../../lib/metamaskhandler";
import logo from "../../../../assets/images/newLogo.png";
import menuIcon from "../../../../assets/images/icon/mint-menu_icon.svg";
import metamaskIcon from "../../../../assets/images/icon/MetaMask.svg";
import { ConnectButton } from '@rainbow-me/rainbowkit';

const Header = () => {
  const { walletModalHandle } = useModal();
  const [isMobileMenu, setMobileMenu] = useState(false);
  const [wallet, setWallet] = useState("");

  const handleMobileMenu = () => {
    setMobileMenu(!isMobileMenu);
  };

  const getWallet = async () => {
    const account = await connectWallet();
    setWallet(account[0])
  };

  useEffect(() => {
    const header = document.getElementById("navbar");
    const handleScroll = window.addEventListener("scroll", () => {
      if (window.pageYOffset > 50) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    });

    return () => {
      window.removeEventListener("sticky", handleScroll);
    };
  }, []);

  return (
    <>
      <HeaderStyleWrapper id="navbar">
        <div className="header_wrapper">
          <div className="header_left">
            <div className="logo">
              <img src={logo} alt="logo" />
            </div>
          </div>
          <div className="header_right">
            <div className="bithu_menu_btns" style={{display: "flex", alignItems: "center"}}>
            <div className="bithu_menu_list">
                <ul>
                  <li>
                    <a href="/peds">Peds</a>
                  </li>
                </ul>
              </div>
              {wallet == "" ? <Button
                style={{ width: 200 }}
                variant="hovered"
                className="connect_btn"
                onClick={() => getWallet()}
              >
                <img src={metamaskIcon} alt="Meta-mask" />
                Connect MetaMask
              </Button> : <Button
                sm
                variant="hovered"
                className="connect_btn"
                onClick={() => getWallet()}
              >
                <FaWallet />
                {wallet.substr(0, 4)}...{wallet.substr(wallet.length - 4)}
              </Button>}
              {wallet == "" ? <div></div> : <a href="/profile"><Button
                sm
                variant="hovered"
              >
                <FaUser />
                Minha Conta
              </Button></a>}
              {/* <Button
                variant="outline"
                className="menu_btn"
                onClick={() => handleMobileMenu()}
              >
                <img src={menuIcon} alt="menu bar icon" />
              </Button> */}
            </div>
          </div>
        </div>
      </HeaderStyleWrapper>
      {isMobileMenu && <Navigation mobileMenuHandle={handleMobileMenu} />}
    </>
  );
};

export default Header;
