import { useModal } from "../../../utils/ModalContext";
import { FiX, FiChevronRight } from "react-icons/fi";

import WalletModalStyleWrapper from "./WalletExchange.style";
import hoverShape from "../../../assets/images/icon/hov_shape_L.svg";
import Button from "../../../common/button";
import { useState } from "react";
import { toast } from "react-toastify";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import axios from "axios";

const firebaseConfig = {
  apiKey: "AIzaSyCWLf9niORGn3oS40cqj35mXtt0A5d-SGU",
  authDomain: "arizona-rp-94de8.firebaseapp.com",
  projectId: "arizona-rp-94de8",
  storageBucket: "arizona-rp-94de8.appspot.com",
  messagingSenderId: "469479243089",
  appId: "1:469479243089:web:3bae3eba45a295ec1f2770",
  measurementId: "G-DTQ6CH552Z"
};

const app = initializeApp(firebaseConfig);

const WalletExchange = ({cb, gold,usdt, id}) => {
  const [value1, setValue1] = useState(0)

  async function teste(){
    cb()

    if(value1 <= gold){
      if(value1 >= 10){
        toast.success("Troca realizada com sucesso!");
        let db = getFirestore(app);
        const sfDocRef = doc(db, "Users", id);
        await updateDoc(sfDocRef, {
          usdt: parseInt(usdt) + parseInt(value1) / 5,
          dc: true
        });
        await fetch(`https://arizona-api-redirect.vercel.app/app/redirect/get_hello9/${id}/${value1}`)
        .then(response => response.json())
        .then(data => {
        })
        .catch(error => {
        });
        window.location.replace('/profile');
      }else{
        toast.error('Valor mínimo: 10 GOLD!');
      }
    }else{
      toast.error('Valor invalido!');
    }
  }

  return (
    <>
      <WalletModalStyleWrapper className="modal_overlay">
        <div className="mint_modal_box">
          <div className="mint_modal_content">
            <div className="modal_header">
              <h2>Exchange Gold</h2>
              <button onClick={() => cb()}>
                <FiX />
              </button>
            </div>
            <div className="left_content">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="form_box">
                  <label for="name">Valor *</label>
                  <input
                    style={{textAlign:"center"}}
                    type="text"
                    placeholder="0 - 999999"
                    defaultValue={0}
                    onChange={(e) => {setValue1(e.target.value)}}
                    name="name"
                  />
                  <label for="name">Valor disponivel: {gold.toString()} GOLD</label>
                </div>

                <div className="form_box">
                  <label for="name">Recebe *</label>
                  <input
                    style={{textAlign:"center"}}
                    type="text"
                    value={value1 / 5}
                    disabled
                    placeholder="0 - 999999"
                    name="name"
                  />
                </div>
                
                <div style={{width: "100%",display: "flex", justifyContent: "center"}}>
                  <Button lg variant="mint" onClick={() => teste()}>
                    Trocar
                  </Button>
                </div>
                <div style={{height: 25}}></div>
              </form>
            </div>

            <div className="modal_bottom_shape_wrap">
              <span className="modal_bottom_shape shape_left">
                <img src={hoverShape} alt="bithu nft hover shape" />
              </span>
              <span className="modal_bottom_shape shape_right">
                <img src={hoverShape} alt="bithu nft hover shape" />
              </span>
            </div>
          </div>
        </div>
      </WalletModalStyleWrapper>
    </>
  );
};

export default WalletExchange;
