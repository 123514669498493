import TeamMemberStyleWrapper from "./TeamMember.style";
import hoverShape from "../../../src/assets/images/icon/hov_shape_L.svg"
import avatarThumb1 from "./avatar.png";
import usdt_img from "./usdt.png";
import gold_img from "./gold.png";

import { useEffect } from "react";
import { useState } from "react";
import WalletExchange from "./WalletExchange/WalletExchange";
import WalletHistory from "./WalletHistory/WalletHistory";
import WalletWithdrawal from "./WalletWithdrawal/WalletWithdrawal";

const TeamConnected = ({ gold, usdt, load, id }) => {
  const [modal_exchange, setModalExchange] = useState(false)
  const [modal_history, setModalHistory] = useState(false)
  const [modal_saque, setModalSaque] = useState(false)

  function teste() {
    setModalExchange(false)
  }

  function teste2() {
    setModalHistory(false)
  }

  function teste3() {
    setModalSaque(false)
  }

  return (
   <TeamMemberStyleWrapper>
      {!load ? <div className="bithu_team_card">
        <div style={{width: "100%",display: "flex", justifyContent: "space-around", alignItems: "center"}}>
          <div>
            <div className="team_member_img">
              <img src={avatarThumb1} style={{width: 65, borderRadius: "50%"}} />
            </div>
            <div style={{height: 10}}></div>
            <h4 >Carteira<br/>vinculada</h4>
          </div>
          <div>
            <div style={{width: "125px",height: "25px",backgroundColor: "#040C12", borderRadius: "10px", display: "flex", justifyContent: "space-between"}}>
              <div style={{display: "flex"}}>
                <div style={{width: 15}}></div>
                <p className="text">{usdt}</p>
              </div>
              <img src={usdt_img} style={{width: 22, height: 22, borderRadius: "50%"}} />
            </div>
            <div style={{height: 20}}></div>
            <div style={{width: "125px",height: "25px",backgroundColor: "#040C12", borderRadius: "10px", display: "flex", justifyContent: "space-between"}}>
              <div style={{display: "flex"}}>
                <div style={{width: 15}}></div>
                <p className="text">{gold.toString()}</p>
              </div>
              <img src={gold_img} style={{width: 24, height: 24, borderRadius: "50%"}} />
            </div>
          </div>
        </div>
        <div style={{height: 30}}></div>

        <div onClick={()=> setModalExchange(true)} style={{width: "70%",height: "30px",backgroundColor: "#040C12", borderRadius: "10px", display: "flex", justifyContent: "center", alignItems: "flex-start", cursor: "pointer"}}>
          <p className="text">Exchange Gold</p>
        </div>
        <div style={{height: 10}}></div>
        <div onClick={()=> setModalHistory(true)} style={{width: "70%",height: "30px",backgroundColor: "#040C12", borderRadius: "10px", display: "flex", justifyContent: "center", alignItems: "flex-start", cursor: "pointer"}}>
          <p className="text">Histórico  Saque</p>
        </div>
        <div style={{height: 10}}></div>
        <div onClick={()=> setModalSaque(true)} style={{width: "70%",height: "30px",backgroundColor: "#040C12", borderRadius: "10px", display: "flex", justifyContent: "center", alignItems: "flex-start", cursor: "pointer"}}>
          <p className="text">Saque</p>
        </div>
        <span className="hover_shape hov_shape1">
          <img src={hoverShape} alt="bithu nft team" />
        </span>
        <span className="hover_shape hov_shape2">
          <img src={hoverShape} alt="" />
        </span>
      </div>:<div className="text">carregando</div>}
      {modal_history && <WalletHistory cb={() => teste2()} />}
      {modal_saque && <WalletWithdrawal cb={() => teste3()} usdt={usdt} id={id}  />}
      {modal_exchange && <WalletExchange cb={() => teste()} gold={gold} usdt={usdt} id={id} />}
    </TeamMemberStyleWrapper>
  );
};

export default TeamConnected;
