import Counter from "../../../../common/counter";
import bgShape1 from "../../../../assets/images/icon/square_shape_1.png";
import bgShape2 from "../../../../assets/images/icon/square_shape_2.png";

import CoinInfoCounterWrapper from "./Counter.style";
import coinInfoCounterData from "../../../../assets/data/coinInfoCounter.json";
import { useEffect, useState } from "react";
import { collection, doc, getFirestore, onSnapshot, query } from "firebase/firestore";
import App from "../../../../app/App";

const CoinInfoCounter = () => {
  const { info } = coinInfoCounterData;

  const [codes, setCodes] = useState(0)
  const [value_genareted, setValueGenareted] = useState(0)
  const [users, setUsers] = useState(0)

  useEffect(()=>{
    load()
  },[])

  async function load(){
    let db = getFirestore(App);

    const q = query(doc(db, "Indicators", "Indicators"));

    onSnapshot(q, async (querySnapshot) => {
      console.log(querySnapshot.data().value_genareted)
      setUsers(querySnapshot.data().users)
      setCodes(querySnapshot.data().codes)
      setValueGenareted(querySnapshot.data().value_genareted)
    });
  }

  return (
    <CoinInfoCounterWrapper>
      <div className="container">
        <ul>
          {info?.map((item, i) => (
            <li key={i}>
              <h3>
                <Counter
                  end={i == 0 ? users : i == 1 ? codes : i == 2 ? value_genareted * 4 : item.number}
                  decimal="."
                  decimals={i == 0 ? "0" : i == 1 ? "0" : i == 2 ? "3" : i == 3 ? "1" : "0"}
                  suffix={item.unit}
                />
              </h3>
              <h4>{item.text}</h4>
            </li>
          ))}
        </ul>
      </div>
      <img
        src={bgShape2}
        className="bg-shape shape-left shape-top"
        alt="bithu bg shape"
      />
      <img
        src={bgShape1}
        className="bg-shape shape-left shape-bottom"
        alt="bithu bg shape"
      />
      <img
        src={bgShape2}
        className="bg-shape shape-right shape-top"
        alt="bithu bg shape"
      />
      <img
        src={bgShape1}
        className="bg-shape shape-right shape-bottom"
        alt="bithu bg shape"
      />
    </CoinInfoCounterWrapper>
  );
};

export default CoinInfoCounter;
