import { useModal } from "../../../../utils/ModalContext";
import Counter from "../../../../common/counter";
import Button from "../../../../common/button";
import BannerV1Wrapper from "./Banner.style";

import characterThumb from "../../../../assets/images/nft/apps.png";
import google from "../../../../assets/images/nft/google.png";
import apple from "../../../../assets/images/nft/apple.png";
import mintLiveDownArrow from "../../../../assets/images/nft/mint_live_down_arrow.svg";
import mintLiveText from "../../../../assets/images/nft/mint_live_text.png";
import homeImageBG from "../../../../assets/images/nft/home_img_bg.png";

import { useAccount, useContractRead, useContractWrite } from 'wagmi';
import { useState, useEffect } from "react";
import {
  maxSupplyCall,
  totalMintedCall,
  maxMintPerWalletCall,
  publicMintCostCall,
} from "../../../../contract/config";
import { toast } from "react-toastify";

const Banner = () => {
  const { mintModalHandle } = useModal();

  const [totalSupply, setTotalSupply] = useState(5555);
  const [totalMinted, setTotalMinted] = useState(5555);
  const [remainingItem, setRemainingItem] = useState(5555);
  const [maxMintPerWallet, setMaxMintPerWallet] = useState(2);
  const [publicMintCost, setPublicMintCost] = useState(0.09);

  const { address, isConnecting, isConnected, isDisconnected } = useAccount();

  const { data: maxSupplyData } = useContractRead({ ...maxSupplyCall })
  const { data: totalMintedData } = useContractRead({ ...totalMintedCall })
  const { data: maxMintPerWalletData } = useContractRead({ ...maxMintPerWalletCall })
  const { data: publicMintCostData } = useContractRead({ ...publicMintCostCall })

  useEffect(() => {
    if (isConnected) {
      if (maxSupplyData) {
        setTotalSupply(maxSupplyData.toString());
      }
      if (totalMintedData) {
        setTotalMinted(totalMintedData.toString());
      }
      if (maxSupplyData && totalMintedData) {
        setRemainingItem(totalSupply - totalMinted);
      }
      if (maxMintPerWalletData) {
        setMaxMintPerWallet(maxMintPerWalletData.toString());
      }
      if (publicMintCostData) {
        setPublicMintCost(publicMintCostData.toString() / 1000000000000000000);
      }
    }
  })

  function dale(){
    toast.success('Em Breve');
  }

  return (
    <BannerV1Wrapper id="home">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="bithu_v1_baner_left">
              <h3>Baixe agora nosso app e começe a ganhar!</h3>
            
        
              <div className="coin-info">
                <span>Bem-vindo ao nosso servidor de RP <span className="highlighted">play-to-earn!</span> Aqui você pode ganhar <span className="highlighted">$USDT</span> enquanto se diverte em uma experiência única no Velho Oeste.</span>
                <div style={{height: "15px"}}></div>
                <span>Seja dono de seu próprio negócio, trabalhe em fazendas e minas, e construa uma fortuna em nosso servidor de RP <span className="highlighted">play-to-earn</span> no Red Dead Redemption 2!</span>
              </div>

              <div className="banner_buttons">
                <a href="https://play.google.com/store/apps/details?id=com.unix.arizona_app" target={"_blank"}>
                  <img src={google} style={{width: "200px", height: "75px", cursor: "pointer"}} alt="avater" />
                </a>
                <a href="https://apps.apple.com/us/app/arizona-county/id1667192016" target={"_blank"}>
                  <img src={apple} style={{width: "200px", height: "58px", marginTop: "10px", cursor: "pointer"}} />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="bithu_v1_baner_right">
              <div className="bithu_v1_baner_right_img_sect">
               
                {/* <div className="bithu_v1_baner_right_img_bg">
                  <img src={homeImageBG} alt="" />
                </div> */}
                <div className="bithu_v1_baner_right_img">
                  <img src={characterThumb} alt="avater" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BannerV1Wrapper>
  );
};

export default Banner;
