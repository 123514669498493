import { Routes, Route } from "react-router-dom";
import ComingSoon from "../pages/comingSoon";
import AboutPage from "../pages/about";
import HomeV1 from "../pages/homeV1";
import CollectionsPage from "../pages/collections";

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomeV1 />} exact />
      <Route path="/" element={<HomeV1 />} />
      <Route path="/profile" element={<AboutPage />} />
      <Route path="/peds" element={<CollectionsPage />} />
    </Routes>
  );
}

export default App;
