import { useModal } from "../../../utils/ModalContext";
import { FiX, FiChevronRight } from "react-icons/fi";

import WalletModalStyleWrapper from "./WalletWithdrawal.style";
import hoverShape from "../../../assets/images/icon/hov_shape_L.svg";
import Button from "../../../common/button";
import { connectWallet } from "../../../lib/metamaskhandler";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { addDoc, collection, doc, getFirestore, setDoc, updateDoc } from "firebase/firestore";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyCWLf9niORGn3oS40cqj35mXtt0A5d-SGU",
  authDomain: "arizona-rp-94de8.firebaseapp.com",
  projectId: "arizona-rp-94de8",
  storageBucket: "arizona-rp-94de8.appspot.com",
  messagingSenderId: "469479243089",
  appId: "1:469479243089:web:3bae3eba45a295ec1f2770",
  measurementId: "G-DTQ6CH552Z"
};

const app = initializeApp(firebaseConfig);

const WalletWithdrawal = ({cb, usdt, id}) => {
  const [wallet, setWallet] = useState("")
  const [value1, setValue1] = useState(0)

  useEffect(()=>{
    getWallet()
  },[])

  const getWallet = async () => {
    const account = await connectWallet();
    setWallet(account[0])
  };

  async function submit(){
    cb()

    if(value1 <= usdt){
      // if(value1 >= 5){
        let db = getFirestore(app);

        const sfDocRef = doc(db, "Users", id);
        await updateDoc(sfDocRef, {
          usdt: parseInt(usdt) - parseInt(value1)
        });

        await addDoc(collection(db, "Withdrawals"), {
          value: parseInt(value1),
          wallet: wallet,
          steam_id: id,
          hash: "-",
          status: "pendente"
        });
        
        toast.success('Saque solicitado com sucesso!');
      // }else{
      //   toast.error('Valor mínimo: 5 USDT!');
      // }
    }else{
      toast.error('Valor invalido!');
    }
  }

  return (
    <>
      <WalletModalStyleWrapper className="modal_overlay">
        <div className="mint_modal_box">
          <div className="mint_modal_content">
            <div className="modal_header">
              <h2>Saque</h2>
              <button onClick={() => cb()}>
                <FiX />
              </button>
            </div>
            <div className="left_content">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="form_box">
                  <label for="name">Wallet *</label>
                  <input
                    style={{textAlign:"center"}}
                    type="text"
                    disabled
                    placeholder={ `${wallet.substr(0, 6)}...${wallet.substr(wallet.length - 6)}`}
                    name="name"
                  />
                  <label for="name">Valor disponivel: {usdt.toString()} USDT</label>
                </div>
                <div className="form_box">
                  <label for="name">Valor *</label>
                  <input
                    style={{textAlign:"center"}}
                    type="text"
                    onChange={(e) => {setValue1(e.target.value)}}
                    placeholder="0 - 999999"
                    name="name"
                  />
                  <label for="name">Valor disponivel: {usdt.toString()} USDT</label>
                </div>

                <div style={{width: "100%",display: "flex", justifyContent: "center"}}>
                  <Button onClick={() => submit()} lg variant="mint">
                    Solicitar Saque
                  </Button>
                </div>
                <div style={{height: 25}}></div>
              </form>
            </div>
            <div className="modal_bottom_shape_wrap">
              <span className="modal_bottom_shape shape_left">
                <img src={hoverShape} alt="bithu nft hover shape" />
              </span>
              <span className="modal_bottom_shape shape_right">
                <img src={hoverShape} alt="bithu nft hover shape" />
              </span>
            </div>
          </div>
        </div>
      </WalletModalStyleWrapper>
    </>
  );
};

export default WalletWithdrawal;
